import { Config, getOption } from '../Config.js'
import axios from 'axios'

const ALFABANK_EXCHANGE_URL = 'https://alfabank.ru/ext-json/0.2/exchange/cash?offset=0&limit=2&mode=rest'
const KRAKEN_ORDER_BOOK_URL = 'https://api.kraken.com/0/public/Depth?pair=xbteur'
const FEE_LIST = 'https://bitcoinfees.earn.com/api/v1/fees/list'

export function getEurPrice(currencyData) {
  return currencyData.eur.filter(item => item.type === 'sell')[0].value
};

export function getEurUsdCurrency(currencyData) {
  const eurSell = currencyData.eur.filter(item => item.type === 'sell')
  const eur0 = eurSell[0].value
  const eur1 = eurSell[1].value
  const usdSell = currencyData.usd.filter(item => item.type === 'sell')
  const usd0 = usdSell[0].value
  const usd1 = usdSell[1].value
  return {
    usd: usd0,
    eur: eur0,
    usdTrend: (usd0 === usd1 ? 0 : (usd0 > usd1 ? 1 : -1)),
    eurTrend: (eur0 === eur1 ? 0 : (eur0 > eur1 ? 1 : -1)),
  }
};

export async function getCurrencyData() {
  const response = await axios.get(getOption(Config.CORS_PROXY_URL) + ALFABANK_EXCHANGE_URL)
  const currencyData = response.data
  currencyData.timestamp = new Date().getTime()
  return currencyData
}

export async function getKrakenAsksData() {
  const response = await axios.get(getOption(Config.CORS_PROXY_URL) + KRAKEN_ORDER_BOOK_URL)
  const asks = response.data.result.XXBTZEUR.asks
  return asks
};

export async function getFeeListData() {
  const response = await axios.get(getOption(Config.CORS_PROXY_URL) + FEE_LIST)
  const feeList = response.data.fees
  return feeList
}