export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('katlex-crypto-bay')
    if (serializedState) {
      return JSON.parse(serializedState)
    }
  } catch (error) {
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('katlex-crypto-bay', serializedState)
  } catch (error) {
  }
}
