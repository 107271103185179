import React, { Component } from 'react'
import FeePanel from './FeePanel'
import { connect } from 'react-redux'
import { setFeePerByteEstimatedText, setNumberOfBlocksEstimatedText, setCalculationWay, recalculateFee } from '../store/fee/actions'
import { bindActionCreators } from 'redux'

class FeePanelContainer extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.feeList.timestamp !== this.props.feeList.timestamp) {
      this.props.recalculateFee()
    }
  }

  render() {
    return (
      <FeePanel
        feePerByteEstimated={this.props.feePerByteEstimated}
        numberOfBlocksEstimated={this.props.numberOfBlocksEstimated}
        calculationWay={this.props.calculationWay}
        setFeePerByteEstimatedText={this.props.setFeePerByteEstimatedText}
        setNumberOfBlocksEstimatedText={this.props.setNumberOfBlocksEstimatedText}
        setCalculationWay={this.props.setCalculationWay}
        recalculateFee={this.props.recalculateFee}
        fee={this.props.fee}
        feePerByte={this.props.feePerByte}
        estimation={this.props.estimation}
        classes={this.props.classes}
        loading={this.props.loading}
      />)
  }
}

const mapStateToProps = (state) => {
  return {
    feePerByteEstimated: state.fee.feePerByteEstimated,
    numberOfBlocksEstimated: state.fee.numberOfBlocksEstimated,
    calculationWay: state.fee.calculationWay,
    fee: state.fee.fee,
    feePerByte: state.fee.feePerByte,
    estimation: state.fee.estimation,
    loading: state.fee.loading,
    feeList: state.loadedData.feeList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFeePerByteEstimatedText: bindActionCreators(setFeePerByteEstimatedText, dispatch),
    setNumberOfBlocksEstimatedText: bindActionCreators(setNumberOfBlocksEstimatedText, dispatch),
    setCalculationWay: bindActionCreators(setCalculationWay, dispatch),
    recalculateFee: bindActionCreators(recalculateFee, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeePanelContainer)