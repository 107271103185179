import { SET_TO, SET_FROM, SET_CALCULATED, SET_ERROR_MESSAGE} from './actions'
import { Config, getOption } from '../../Config.js'
import { BTC, RUB } from '../../constants/Currency'

const defaultState = {
  value: getOption(Config.DEFAULT_CALC_RUB_AMOUNT),
  calculated: 0.001,
  currency1: RUB,
  currency2: BTC,
  baseCurrency: 1,
  errorMessage: '',
}

export const converterReducer = (state = defaultState, action) => {
  state = { ...defaultState, ...state }
  switch (action.type) {
    case SET_FROM:
      return {
        ...state,
        value: action.payload.value,
        currency1: action.payload.currency1,
        currency2: action.payload.currency2,
        baseCurrency: action.payload.baseCurrency,
      }
    case SET_TO:
      return {
        ...state,
        value: action.payload.value,
        currency1: action.payload.currency1,
        currency2: action.payload.currency2,
        baseCurrency: action.payload.baseCurrency,
      }
    case SET_CALCULATED:
      return {
        ...state,
        calculated: action.payload,
      }
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}