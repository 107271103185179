import { TRANSFER_CHANGE_BTC_ADDRESS } from './actions'

const defaultState = {
  btcAddress: '',
}

export const transferReducer = (state = defaultState, action) => {
  state = { ...defaultState, ...state }
  switch (action.type) {
    case TRANSFER_CHANGE_BTC_ADDRESS:
      return {
        ...state,
        btcAddress: String(action.payload).trim() ,
      }
    default:
      return state
  }
}