import React, {useState} from 'react'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import IconButton from '@material-ui/core/IconButton'
import { Tooltip } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export function CopyButton ({text, tooltip}) {
  const [copied, setCopied] = useState(false)
  const [copyError, setCopyError] = useState(false)
  const [tooltipOpened, setTooltipOpened] = useState(false)
  const onCopy = (text, result) => {
    setCopyError(!result)
    setTooltipOpened(true)
    setCopied(true)
  }
  const onOpen = () => {
    setTooltipOpened(true)
  }
  const onClose = () => {
    setTooltipOpened(false)
    setCopied(false)
  }
  return (
    <Tooltip 
      title={copied ? (copyError ? 'Copy error' : 'Copied!') : tooltip} 
      onClose={onClose} 
      onOpen={onOpen}
      open={tooltipOpened}
      placement="bottom" 
      leaveDelay={0} 
      TransitionProps={{ timeout: 0 }}
    >
      <CopyToClipboard onCopy={onCopy} text={text}>
        <IconButton aria-label="copy" >
          <FileCopyOutlinedIcon fontSize="inherit"/>
        </IconButton>
      </CopyToClipboard>
    </Tooltip>
  )
}

export default CopyButton
