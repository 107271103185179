import {
  FEE_CHANGE_FEE_PER_BYTE_ESTIMATED_TEXT,
  FEE_CHANGE_NUMBER_OF_BLOCKS_ESTIMATED_TEXT,
  FEE_CHANGE_CALCULATION_WAY,
  FEE_UPDATED,
  FEE_LOADING,
} from './actions'

import { NUMBER_OF_BLOCKS_ESTIMATED } from './listIds'

const defaultState = {
  feePerByteEstimated: 30,
  numberOfBlocksEstimated: 3,
  calculationWay: NUMBER_OF_BLOCKS_ESTIMATED,
  loading: false,
  fee: 0,
  feePerByte: 0,
  estimation: ''
}

export const feeReducer = (state = defaultState, action) => {
  state = { ...defaultState, ...state }
  switch (action.type) {
    case FEE_CHANGE_FEE_PER_BYTE_ESTIMATED_TEXT:
      return {
        ...state,
        feePerByteEstimated: action.payload
      }
    case FEE_CHANGE_NUMBER_OF_BLOCKS_ESTIMATED_TEXT:
      return {
        ...state,
        numberOfBlocksEstimated: action.payload
      }
    case FEE_CHANGE_CALCULATION_WAY:
      return {
        ...state,
        calculationWay: action.payload
      }
    case FEE_UPDATED:
      return {
        ...state,
        fee: action.payload.result,
        feePerByte: action.payload.feePerByte,
        estimation: action.payload.estimation,
      }
    case FEE_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}