import React, { useEffect } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Typography, Paper, Grid, TextField, FormLabel, FormControl, makeStyles } from '@material-ui/core'

import { FEE_PER_BYTE_ESTIMATED, NUMBER_OF_BLOCKS_ESTIMATED } from '../store/fee/listIds'
import { Select, MenuItem, BottomNavigation, BottomNavigationAction } from '@material-ui/core'

import AccessTime from '@material-ui/icons/AccessTime'
import GpsFixed from '@material-ui/icons/GpsFixed'


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    display: 'flex',
  },
  select: {
    height: theme.spacing(7),
    paddingLeft: theme.spacing(1),
    margin: 0,
  },
  floatingLabel: {
    marginTop: -theme.spacing(1),
  },
  textField: {
    margin: 0,
  },
  mainContainer: {
    paddingTop: theme.spacing(2),
  },
}), { name: 'FeePanel' })


export function FeePanel ({
  recalculateFee,
  setCalculationWay,
  setFeePerByteEstimatedText,
  setNumberOfBlocksEstimatedText,
  numberOfBlocksEstimated,
  feePerByteEstimated,
  calculationWay,
  loading,
  fee,
  feePerByte,
  estimation,
}) {
  useEffect(() => {
    if (calculationWay === NUMBER_OF_BLOCKS_ESTIMATED) {
      if ([1, 3, 6, 12].indexOf(numberOfBlocksEstimated) === -1) {
        setNumberOfBlocksEstimatedText(3)
      }
    }
    if (calculationWay !== NUMBER_OF_BLOCKS_ESTIMATED && calculationWay !== FEE_PER_BYTE_ESTIMATED) {
      setCalculationWay(FEE_PER_BYTE_ESTIMATED)
    }
  }, [numberOfBlocksEstimated, calculationWay, setNumberOfBlocksEstimatedText, setCalculationWay])

  useEffect(() => {
    recalculateFee()
  }, [recalculateFee])

  const feePerByteChanged = event => {
    setFeePerByteEstimatedText(event.target.value)
  }

  const numberOfBlocksEstimatedChanged = event => {
    setNumberOfBlocksEstimatedText(event.target.value)
  }

  const classes = useStyles()

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">Fee calculation</FormLabel>
      <Grid container className={classes.mainContainer} >
        <Grid item xs={6}>
          {calculationWay === FEE_PER_BYTE_ESTIMATED && (
            <TextField
              id="feePerByteField"
              value={feePerByteEstimated}
              onChange={feePerByteChanged}
              className={classes.textField}
              type="number"
              margin="normal"
              variant="filled"
              label="Satoshis per byte"
              fullWidth
              inputProps={{min: 0, max: 1000 }}
              InputLabelProps={{
                className: classes.floatingLabel,
              }}
            />
          )}
          {calculationWay === NUMBER_OF_BLOCKS_ESTIMATED && (
            <Select
              label="Estimated"
              fullWidth
              variant="filled"
              className={classes.select}
              onChange={numberOfBlocksEstimatedChanged}
              value={numberOfBlocksEstimated}
            >
              <MenuItem value={1}>Fastest</MenuItem>
              <MenuItem value={3}>1/2 hour</MenuItem>
              <MenuItem value={6}>1 hour</MenuItem>
              <MenuItem value={12}>Slow</MenuItem>
            </Select>
          )}
        </Grid>
        <Grid item xs={6}>
          <BottomNavigation
            value={calculationWay}
            onChange={(event, newValue) => {
              setCalculationWay(newValue)
            }}
            showLabels
            className={classes.root}
          >
            <BottomNavigationAction value={FEE_PER_BYTE_ESTIMATED} label="Fixed" icon={<GpsFixed />} />
            <BottomNavigationAction value={NUMBER_OF_BLOCKS_ESTIMATED} label="Estimated" icon={<AccessTime />} />
          </BottomNavigation>
        </Grid>
      </Grid>
      <div style={{ paddingTop: '10px' }} />
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
      <Paper className={classes.paper} elevation={1}>
        <Grid container spacing={3}>
          <Grid item container direction="column" xs>
            <Grid item>
              <Typography component="p" align="right">
                Total fee
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p" align="right">
               Fee per byte
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p" align="right">
                Estimated blocks
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs>
            <Grid item>
              <Typography component="p">
                {fee}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p">
                {feePerByte}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="p">
                {estimation}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </FormControl>
  )
}
export default FeePanel