import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import CopyButton from './CopyButton'

const styles = theme => ({
  container: {},
  textField: {},
  textFieldSelected: {},
  progress: {},
  button: {},
  formControl: {},
  paper: {},
  grid: {},
  currencyText: {},
})

export class Currency extends Component {
  render() {
    const { classes } = this.props
    return (
      <Grid container justify="center" direction="row" alignItems="center">
        <CopyButton 
          text={this.props.usd} 
          tooltip="Copy USD currency" 
        />
        <Typography variant="body2" className={classes.currencyText}>
          USD: {this.props.usd + (this.props.usdTrend > 0 ? '\u2B06' : (this.props.usdTrend < 0 ? '\u2B07' : ''))}
        </Typography>
        <Typography variant="body2" className={classes.currencyText}>
          EUR: {this.props.eur + (this.props.eurTrend > 0 ? '\u2B06' : (this.props.eurTrend < 0 ? '\u2B07' : ''))}
        </Typography>
        <CopyButton 
          text={this.props.eur} 
          tooltip="Copy EUR currency" 
        />
      </Grid>
    )
  }
}
export default withStyles(styles)(Currency)