import React, { Component } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Header from './components/Header'
import { Footer } from './components/Footer'
import Main from './components/Main'
import TermsOfService from './components/TermsOfService'
import About from './components/About'
import MediaQuery from 'react-responsive'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import lightGreen from '@material-ui/core/colors/lightGreen'

const theme = createMuiTheme({
  palette: {
    secondary: lightGreen,
  },
})

class App extends Component {
  mainViewStyle = {
    marginLeft: '25%',
    width: '50%',
  }
  mobileViewStyle = {
    marginLeft: '0px',
    width: '100%'
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <div>
            <nav>
              <Header />
            </nav>
            <MediaQuery minDeviceWidth={1000}>
              {(matches) => {
                return <div id="mainView" style={matches ? this.mainViewStyle : this.mobileViewStyle}>
                  <Route path="/" exact component={Main} />
                  <Route path="/about/" component={About} />
                  <Route path="/terms/" component={TermsOfService} />
                </div>
              }}
            </MediaQuery>
            <nav>
              <Footer />
            </nav>
          </div>
        </BrowserRouter>
      </MuiThemeProvider>
    )
  }
}

export default App
