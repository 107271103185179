import { ADD_CONTACT } from './actions'


const defaultState = {
  contactsList: []
}

export const contactsReducer = (state = defaultState, action) => {
  state = { ...defaultState, ...state }
  switch (action.type) {
    case ADD_CONTACT:
      return {
        ...state,
        contactsList: state.contactsList.concat(action.payload)
      }
    default:
      return state
  }
}
