import { SET_CURRENCY_DATA, SET_KRAKEN_ASKS_DATA, SET_FEE_LIST_DATA } from './actions'

const setData = function (data) {
  return {
    data,
    timestamp: new Date().getTime(),
  }
}

const defaultState = {
  currency: {
    data: {},
    timestamp: 0,
  },
  krakenAsks: {
    data: {},
    timestamp: 0,
  },
  feeList: {
    data: {},
    timestamp: 0,
  },
}

export const loadedDataReducer = (state = defaultState, action) => {
  state = { ...defaultState, ...state }
  switch (action.type) {
    case SET_CURRENCY_DATA:
      return {
        ...state,
        currency: setData(action.payload)
      }
    case SET_KRAKEN_ASKS_DATA:
      return {
        ...state,
        krakenAsks: setData(action.payload)
      }
    case SET_FEE_LIST_DATA:
      return {
        ...state,
        feeList: setData(action.payload)
      }
    default:
      return state
  }
}