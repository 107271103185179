export class Config {
  static SERVICE_SITE = 'Config.ServiceSite'
  static SERVICE_NAME = 'Config.ServiceName'
  static DEFAULT_CALC_RUB_AMOUNT = 'Config.DefaultCalcRubAmount'
  static KRAKEN_FEE = 'Config.KrakenFee'
  static SYSTEM_FEE = 'Config.SystemFee'
  static CORS_PROXY_URL = 'Config.CorsProxyUrl'
  static SWIFT_FEE = 'Config.SwiftFee'
  static AVERAGE_BTC_TX_SIZE = 'AverageBtcTxSize'
  static MIN_BTC_LOT = 'MinBtcLot'
  static INPUT_DELAY = 'InputDelay'
  static MAX_QOUTE_CACHE_TIME = 'MaxQuoteCacheTime'
}

export function getOption(option) {
  return options[option]
}

const options = {
  [Config.SERVICE_NAME]: 'exchange.katlex.com',
  [Config.SERVICE_SITE]: 'https://exchange.katlex.com',
  [Config.DEFAULT_CALC_RUB_AMOUNT]: 1000,
  [Config.KRAKEN_FEE]: 0.0026,
  [Config.SYSTEM_FEE]: 0.01,
  [Config.SWIFT_FEE]: 0.01,
  [Config.CORS_PROXY_URL]: 'https://cors.katlex.com/',
  [Config.AVERAGE_BTC_TX_SIZE]: 225,
  [Config.MIN_BTC_LOT]: 0.001,
  [Config.INPUT_DELAY]: 500,
  [Config.MAX_QOUTE_CACHE_TIME]: 60000,//milliseconds
}



