export const SET_CURRENCY_DATA = 'SET_CURRENCY_DATA'
export const SET_KRAKEN_ASKS_DATA = 'SET_KRAKEN_ASKS_DATA'
export const SET_FEE_LIST_DATA = 'SET_FEE_LIST_DATA'

export const setCurrencyData = currencyObject => ({
  type: SET_CURRENCY_DATA,
  payload: currencyObject
})

export const setKrakenAsksData = krakenObject => ({
  type: SET_KRAKEN_ASKS_DATA,
  payload: krakenObject
})

export const setFeeListData = feeListObject => ({
  type: SET_FEE_LIST_DATA,
  payload: feeListObject
})
