
import { Config, getOption } from '../Config.js'
import Big from 'big.js'

export function convertRubToBtc(rub, btcAsks, eurPrice, fee) {
  if (rub <= 0) {
    return 0
  }
  const eur = Big(rub).div(eurPrice)
  const systemFee = getOption(Config.SYSTEM_FEE)
  const swiftFee = getOption(Config.SWIFT_FEE)
  const eurAfterFee = eur.times(Big(1).minus(systemFee).minus(swiftFee))

  let leftEur = eurAfterFee
  let btcAcc = Big(0)
  btcAsks.find(ask => {
    const eurInAsk = Big(ask[0]).times(ask[1])
    if (leftEur.lt(eurInAsk)) {
      btcAcc = btcAcc.plus(leftEur.div(ask[0]))
      return true
    }
    leftEur = leftEur.minus(eurInAsk)
    btcAcc = btcAcc.plus(ask[1])
    return false
  })
  const krakenFee = getOption(Config.KRAKEN_FEE)
  const btc = btcAcc.times(Big(1).minus(krakenFee)).minus(Big(fee).times(0.00000001))
  return (btc.toFixed() > 0 ? btc.toFixed(8) : 0)
}

export function convertBtcToRub(btc, btcAsks, eurPrice, fee) {
  if (btc <= 0) {
    return 0
  }
  const systemFee = getOption(Config.SYSTEM_FEE)
  const krakenFee = getOption(Config.KRAKEN_FEE)
  const swiftFee = getOption(Config.SWIFT_FEE)
  const btcBeforeFee = Big(btc).plus(Big(fee).times(0.00000001)).div(Big(1).minus(krakenFee))
  let eurAcc = Big(0)
  let leftBtc = btcBeforeFee
  btcAsks.find(ask => {
    if (leftBtc.lt(ask[1])) {
      eurAcc = eurAcc.plus(leftBtc.times(ask[0]))
      return true
    }
    eurAcc = eurAcc.plus(Big(ask[1]).times(ask[0]))
    leftBtc = leftBtc.minus(ask[1])
    return false
  })
  const rub = eurAcc.div(Big(1).minus(systemFee).minus(swiftFee))
    .times(eurPrice)
  return rub.toFixed(2)
};

export function feeByNumberOfBlocksEstimated(numberOfBlocks, feeList) {
  const filteredList = feeList.filter(record => record['maxDelay'] <= numberOfBlocks)
  if (filteredList.length > 0) {
    const feePerByte = Big(filteredList[0]['minFee'])
      .toFixed()
    const result = Big(feePerByte)
      .times(getOption(Config.AVERAGE_BTC_TX_SIZE))
      .toFixed()
    const estimation = filteredList[0]['maxDelay']
    return {
      result,
      feePerByte,
      estimation
    }
  } 
  return {
    result: 0,
    feePerByte: 0,
    estimation: 0
  }
}

export function numberOfBlocksByFee(fee, feeList) {
  const feeValue = Number(fee)
  const filteredList = feeList.filter(record => record['minFee'] <= Big(feeValue).toFixed())
  const record = filteredList.pop()
  if (record) {
    const result = Big(feeValue)
      .times(getOption(Config.AVERAGE_BTC_TX_SIZE))
      .toFixed()
    return {
      result,
      feePerByte: feeValue,
      estimation: record['maxDelay'],
    }
  }
  return {
    result: 0,
    feePerByte: 0,
    estimation: 0
  }
}
