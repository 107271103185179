import React, { Component } from 'react'
import { Typography, Grid } from '@material-ui/core'
import * as packageFile from '../../package.json' 

export class Footer extends Component {
  render() {
    return (
      <Grid container justify="center">
        <Typography variant="caption" align="center">
          Version: {packageFile.version}
        </Typography>
      </Grid>
    )
  }
}