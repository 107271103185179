import { getEurPrice } from '../../services/RatesRequester'
import { convertRubToBtc, convertBtcToRub } from '../../services/BtcConverter'
import { RUB, BTC } from '../../constants/Currency' 
import { Config, getOption } from '../../Config.js'
import lodash from 'lodash'

export const SET_TO = 'SET_TO'
export const SET_FROM = 'SET_FROM'
export const SET_CALCULATED = 'SET_CALCULATED'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'

const debouncedCalculate = lodash.debounce(calculate, getOption(Config.INPUT_DELAY))
const debouncedClearErrorMessage = lodash.debounce(clearErrorMessage, 3000)


export function recalculate() {
  return function (dispatch, getState) {
    calculate(dispatch, getState)
  }
}

export function setFrom(value, currency1, currency2, isDelayed) {
  return function (dispatch, getState) {
    dispatch(setFromAction(value, currency1, currency2))
    if (isDelayed) {
      debouncedCalculate(dispatch, getState, true)
    } else {
      calculate(dispatch, getState)
    }
  }
}

export function setTo(value, currency1, currency2, isDelayed) {
  return function (dispatch, getState) {
    dispatch(setToAction(value, currency1, currency2))
    if (isDelayed) {
      debouncedCalculate(dispatch, getState, true)
    } else {
      calculate(dispatch, getState)
    }
  }
}

function calculate(dispatch, getState, isDelayed) {
  var state = getState()
  const eur = getEurPrice(state.loadedData.currency.data)
  const asks = state.loadedData.krakenAsks.data

  const minBtcLot =  getOption(Config.MIN_BTC_LOT)
  if (state.converter.currency1 === RUB && state.converter.currency2 === BTC) {
    if (state.converter.baseCurrency === 1) {
      const btc = convertRubToBtc(state.converter.value, asks, eur, state.fee.fee)
      if (!isDelayed && btc < minBtcLot) {
        const rub = convertBtcToRub(minBtcLot, asks, eur, state.fee.fee)
        dispatch(setFromAction(rub, RUB, BTC))
        dispatch(setCalculatedAction(minBtcLot))
        minBtcLotErrorMessage(dispatch)
      } else {
        dispatch(setCalculatedAction(btc))
      }
    } else {
      let btc = state.converter.value
      if (!isDelayed && btc < minBtcLot) {
        dispatch(setToAction(minBtcLot, RUB, BTC))
        const rub = convertBtcToRub(minBtcLot, asks, eur, state.fee.fee)
        dispatch(setCalculatedAction(rub))
        minBtcLotErrorMessage(dispatch)
      } else {
        const rub = convertBtcToRub(btc, asks, eur, state.fee.fee)
        dispatch(setCalculatedAction(rub))
      }
    }
  }
}

function minBtcLotErrorMessage(dispatch) {
  dispatch(setErrorMessageAction(`Minimum transaction value is ${getOption(Config.MIN_BTC_LOT)} BTC`))
  debouncedClearErrorMessage(dispatch) 
}

function clearErrorMessage(dispatch) {
  dispatch(setErrorMessageAction(''))
}

const setToAction = (value, currency1, currency2) => ({
  type: SET_TO,
  payload: {
    value,
    currency1,
    currency2,
    baseCurrency: 2,
  }
})

const setFromAction = (value, currency1, currency2) => ({
  type: SET_FROM,
  payload: {
    value,
    currency1,
    currency2,
    baseCurrency: 1,
  }
})

const setErrorMessageAction = (value) => ({
  type: SET_ERROR_MESSAGE,
  payload: value,
})

const setCalculatedAction = (value) => ({
  type: SET_CALCULATED,
  payload: value,
})