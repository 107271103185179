import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import ArrowForward from '@material-ui/icons/ArrowForward'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { RUB, BTC } from '../constants/Currency'
import CopyButton from './CopyButton'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Big from 'big.js'

const styles = theme => ({
  container: {},
  textField: {},
  textFieldSelected: {},
  progress: {},
  button: {},
  formControl: {},
  paper: {},
  grid: {},
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  currencyText: {},
})

export function Converter({
  value,
  calculated,
  baseCurrency,
  recalculate,
  classes,
  setFrom,
  setTo,
  errorMessage,
}) {

  useEffect(() => {
    recalculate()
  }, [recalculate])

  const rubChanged = event => {
    setFrom(event.target.value, RUB, BTC, true)
  }

  const btcChanged = event => {
    try {
      Big.RM = 0
      const value = parseFloat(Big(event.target.value).toFixed(8))
      setTo(value, RUB, BTC, true)
    } catch(e) {
    }
  }

  const rubFocused = event => {
    if (baseCurrency === 2) {
      setFrom(event.target.value, RUB, BTC)
    }
  }

  const btcFocused = event => {
    if (baseCurrency === 1) {
      setTo(event.target.value, RUB, BTC)
    }
  }

  const onInputBlur = (event) => {
    recalculate()
  }

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">RUB to BTC converter</FormLabel>
      <Grid container direction="row" alignItems="center" justify="center">
        <CopyButton 
          text={baseCurrency === 1 ? value : calculated} 
          tooltip="Copy RUB value" 
        />
        <Grid item sm={4} xs>
          <TextField
            id="rubField"
            label={baseCurrency === 1 ? 'RUB give' : 'RUB'}
            className={baseCurrency === 1 ? classes.textFieldSelected : classes.textField}
            value={baseCurrency === 1 ? value : calculated}
            onChange={rubChanged}
            onFocus={rubFocused}
            onBlur={onInputBlur}
            type="number"
            margin="normal"
            variant="filled"
            inputProps={{ min: 0, max: 100000000 }}
            fullWidth
          />
        </Grid>
        <Grid item sm={1}>
          <ArrowForward className={classes.icon} />
        </Grid>
        <Grid item sm={4} xs>
          <Tooltip
            title={errorMessage}
            open={!!errorMessage}
            placement="top"
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: { exit: 0 } }}
          >
            <TextField
              id="btcField"
              label={baseCurrency === 2 ? 'BTC get' : 'BTC'}
              className={baseCurrency === 2 ? classes.textFieldSelected : classes.textField}
              value={baseCurrency === 2 ? value : calculated}
              onChange={btcChanged}
              onFocus={btcFocused}
              onBlur={onInputBlur}
              type="number"
              margin="normal"
              variant="filled"
              inputProps={{ step: '0.001', min: 0, max: 1000 }}
              fullWidth
            />
          </Tooltip>
        </Grid>
        <CopyButton 
          text={baseCurrency === 2 ? value : calculated} 
          tooltip="Copy BTC value" 
        />
      </Grid>
    </FormControl>
  )
}
export default withStyles(styles)(Converter)