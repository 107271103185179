import React, { useRef, useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import QRCode from 'qrcode'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MiddleEllipsis from 'react-middle-ellipsis'
import { Typography, makeStyles, useMediaQuery, Link } from '@material-ui/core'
import WAValidator from 'wallet-address-validator'
import Check from '@material-ui/icons/Check'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    border: 1,
    display: 'flex',
  },
  sendLinkEnabled: {
    background: 'rgba(0, 0, 0, 0.1)',
    padding: 10,
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
    color: 'black',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.15)',
    },
  },
  sendLinkDisabled: {
    cursor: 'default',
  },
}), { name: 'TransferPanel' })

const TransferPanel = ({
  baseCurrency,
  calculated,
  value,
  btcAddress,
  setBtcAddressText,
  feePerByte,
  fee,
  contactsList,
  addContact,
}) => {
  const getNameByAddress = (address) => {
    const option = contactsList.find(option => option.address === address)
    return option && option.name   
  }

  const [qrOpen, setQrOpen] = useState(false)
  const [btcAddressValid, setBtcAddressValid] = useState(false)
  const [btcPositive, setBtcPositive] = useState(false)
  const [name, setName] = useState(getNameByAddress(btcAddress))
  const [addressFieldValue, setAddressFieldValue] = useState({name: getNameByAddress(btcAddress), address: btcAddress})
  const [autocompleteOpen, setAutocompleteOpen] = useState(false)

  useEffect(() => {
    const btc = baseCurrency === 1 ? calculated : value
    setBtcPositive(btc > 0)
  }, [baseCurrency, calculated, value])

  useEffect(() => {
    setBtcAddressValid(WAValidator.validate(btcAddress, 'BTC'))
  }, [btcAddress])

  useEffect(() => {
    if (btcAddressValid) {
      setAutocompleteOpen(false)
    }
  }, [btcAddressValid])

  const classes = useStyles()
  const onChange = (event, newValue) => {
    if (newValue.name) {
      setAddressFieldValue(newValue)
      setName(newValue.name)
    }
  }

  const onInputChange = (event, value) => {
    setName(getNameByAddress(value) || '')
    setBtcAddressText(value)
  }

  const onTransferClick = () => {
    setQrOpen(true)
    if (name && !getNameByAddress(btcAddress)) {
      addContact({name, address: btcAddress})
    }
  }
   
  return (
    <>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Transaction</FormLabel>
        <Grid container alignItems="center"
          justify="center">
          <Grid item xs={12} spacing={1}>
            <Autocomplete
              autoHighlight
              freeSolo
              onChange={onChange}
              onInputChange={onInputChange}
              value={addressFieldValue}
              //inputValue={btcAddress}
              id="free-solo-2-demo"
              disableClearable
              onClose={() => setAutocompleteOpen(false)}
              onOpen={() => setAutocompleteOpen(true)}
              open={autocompleteOpen}
              options={contactsList}
              getOptionLabel={option => option.address}
              renderOption={option => (
                <React.Fragment>
                  {option.name}: {option.address}
                </React.Fragment>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="destination BTC address"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  InputProps={{ 
                    ...params.InputProps, 
                    endAdornment: btcAddressValid ? <Check/> : <ErrorOutline/>,
                    type: 'search' 
                  }}
                />
              )}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} alignItems="center">
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={event => setName(event.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                disabled={!btcAddressValid || !btcPositive}
                id="transferButton"
                variant="contained"
                className={classes.button}
                onClick={onTransferClick}>
                Transfer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormControl>

      <Dialog
        open={qrOpen}
        onClose={() => setQrOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Transfer</DialogTitle>
        <DialogContent style={{ overflowX: 'hidden' }}>
          <DialogInner
            btc={baseCurrency === 1 ? calculated : value}
            address={btcAddress}
            feePerByte={feePerByte}
            fee={fee}
            classes={classes}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}


export function DialogInner({ btc, address, feePerByte, fee, classes }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const qrCodeCanvas = useRef(null)
  const [link, setLink] = useState()
  useEffect(() => {
    const canvas = qrCodeCanvas.current
    if (!canvas) {
      return
    }
    const context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height)
    const text = 'bitcoin:' + address + '?amount=' + btc
    setLink(text)
    QRCode.toCanvas(canvas, text)
  }, [btc, address, qrCodeCanvas])
  return <Grid container direction="column">
    <Typography variant="body1">
      <b>
        {`${btc} `}
      </b>
      BTC to
    </Typography>
    <MiddleEllipsis>
      <Typography variant="body1" style={{ maxWidth: '100%' }}>
        <span>
          {address}
        </span>
      </Typography>
    </MiddleEllipsis>
    <Typography variant="body1">
      Fee is <b>{feePerByte}</b> Satoshis per byte
    </Typography>
    <Typography variant="body1">
      Total fee is <b>{fee}</b> Satoshis
    </Typography>
    <Link 
      href={link} 
      className={isMobile ? classes.sendLinkEnabled : classes.sendLinkDisabled} 
      onClick={event => !isMobile && event.preventDefault()}
    >
      <Grid item container alignItems="center" justify="center">
        <canvas ref={qrCodeCanvas} />
      </Grid>
      {isMobile && (
        <Grid container justify="center">
          <Typography variant="body1">
            Click to send
          </Typography>
        </Grid>
      )}
    </Link>
    
  </Grid>
}

export default TransferPanel