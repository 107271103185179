import React, { Component } from 'react'
import Converter from './Converter'
import { connect } from 'react-redux'
import { setFrom, setTo, recalculate } from '../store/converter/actions'
import { bindActionCreators } from 'redux'

class ConverterContainer extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.currency.timestamp !== this.props.currency.timestamp ||
      prevProps.krakenAsks.timestamp !== this.props.krakenAsks.timestamp) {
      this.props.recalculate()
    }
  }

  render() {
    return (
      <Converter
        value={this.props.value}
        calculated={this.props.calculated}
        baseCurrency={this.props.baseCurrency}
        recalculate={this.props.recalculate}
        classes={this.props.classes}
        setFrom={this.props.setFrom}
        setTo={this.props.setTo}
        errorMessage={this.props.errorMessage}
      />)
  }
}

const mapStateToProps = (state) => {
  return {
    value: state.converter.value,
    calculated: state.converter.calculated,
    baseCurrency: state.converter.baseCurrency,
    errorMessage: state.converter.errorMessage,
    currency: state.loadedData.currency,
    krakenAsks: state.loadedData.krakenAsks,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    recalculate: bindActionCreators(recalculate, dispatch),
    setFrom: bindActionCreators(setFrom, dispatch),
    setTo: bindActionCreators(setTo, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConverterContainer)