import { Config, getOption } from '../Config.js'

export function isEmpty(obj) {
  for (var key in obj) {
    return false
  }
  return true
}

export function removeStaleData(data = {}) {
  const keys = Object.keys(data)
  keys.forEach(key => {
    if ((new Date().getTime() - data[key].timestamp) > getOption(Config.MAX_QOUTE_CACHE_TIME)) {
      delete data[key]
    }
  })
}