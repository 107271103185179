import { FEE_PER_BYTE_ESTIMATED, NUMBER_OF_BLOCKS_ESTIMATED } from './listIds'
import {
  numberOfBlocksByFee,
  feeByNumberOfBlocksEstimated, 
} from '../../services/BtcConverter'
import { recalculate as recalculateBtc } from '../converter/actions'

export const FEE_CHANGE_FEE_PER_BYTE_ESTIMATED_TEXT = 'FEE_CHANGE_FEE_PER_BYTE_ESTIMATED_TEXT'
export const FEE_CHANGE_NUMBER_OF_BLOCKS_ESTIMATED_TEXT = 'FEE_CHANGE_NUMBER_OF_BLOCKS_ESTIMATED_TEXT'
export const FEE_CHANGE_CALCULATION_WAY = 'FEE_CHANGE_CALCULATION_WAY'
export const FEE_UPDATED = 'FEE_UPDATED'
export const FEE_LOADING = 'FEE_LOADING'

export function setFeePerByteEstimatedText(text) {
  return function (dispatch, getState) {
    dispatch(setFeePerByteEstimatedAction(text))
    recalculate(dispatch, getState)
  }
}

export function setNumberOfBlocksEstimatedText(text) {
  return function (dispatch, getState) {
    dispatch(setNumberOfBlocksEstimatedAction(text))
    recalculate(dispatch, getState)
  }
}

export function setCalculationWay(way) {
  return function (dispatch, getState) {
    dispatch(setCalculationWayAction(way))
    recalculate(dispatch, getState)
  }
}

export function recalculateFee() {
  return function (dispatch, getState) {
    recalculate(dispatch, getState)
  }
}

const setFeePerByteEstimatedAction = text => ({
  type: FEE_CHANGE_FEE_PER_BYTE_ESTIMATED_TEXT,
  payload: text
})

const setNumberOfBlocksEstimatedAction = text => ({
  type: FEE_CHANGE_NUMBER_OF_BLOCKS_ESTIMATED_TEXT,
  payload: text
})

const setCalculationWayAction = way => ({
  type: FEE_CHANGE_CALCULATION_WAY,
  payload: way
})

const recalculate = function (dispatch, getState) {
  const state = getState()
  let calculatedFeeObj
  switch (state.fee.calculationWay) {
    case FEE_PER_BYTE_ESTIMATED:
      calculatedFeeObj = numberOfBlocksByFee(state.fee.feePerByteEstimated, state.loadedData.feeList.data)
      break
    case NUMBER_OF_BLOCKS_ESTIMATED:
      dispatch(loading(true))
      calculatedFeeObj = feeByNumberOfBlocksEstimated(state.fee.numberOfBlocksEstimated, state.loadedData.feeList.data)
      dispatch(loading(false))
      break
    default:
  }
  dispatch(feeUpdated(calculatedFeeObj))
  dispatch(recalculateBtc())
}

const feeUpdated = feeObj => ({
  type: FEE_UPDATED,
  payload: feeObj
})

const loading = value => ({
  type: FEE_LOADING,
  payload: value
})
