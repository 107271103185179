import { combineReducers } from 'redux'
import {feeReducer} from './fee/reducers'
import {converterReducer} from './converter/reducers'
import {transferReducer} from './transfer/reducers'
import {loadedDataReducer} from './loadedData/reducers'
import {contactsReducer} from './contacts/reducers'

export default combineReducers({
  fee: feeReducer,
  converter: converterReducer,
  transfer: transferReducer,
  loadedData: loadedDataReducer,
  contacts: contactsReducer,
})