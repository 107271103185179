import React, { Component } from 'react'
import TransferPanel from './TransferPanel'
import { connect } from 'react-redux'
import { setBtcAddressText } from '../store/transfer/actions'
import { bindActionCreators } from 'redux'
import { addContact } from '../store/contacts/actions'

class TransferPanelContainer extends Component {
  render() {
    return (
      <TransferPanel
        value={this.props.value}
        calculated={this.props.calculated}
        baseCurrency={this.props.baseCurrency}
        btcAddress = {this.props.btcAddress}
        feePerByte ={this.props.feePerByte}
        fee={this.props.fee}
        setBtcAddressText = {this.props.setBtcAddressText}
        contactsList={this.props.contactsList}
        addContact = {this.props.addContact}
        classes={this.props.classes}
      />)
  }
}

const mapStateToProps = (state) => {
  return {
    value: state.converter.value,
    calculated: state.converter.calculated,
    baseCurrency: state.converter.baseCurrency,
    feePerByte: state.fee.feePerByte,
    fee: state.fee.fee,
    btcAddress: state.transfer.btcAddress,
    contactsList: state.contacts.contactsList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBtcAddressText: bindActionCreators(setBtcAddressText, dispatch),
    addContact: bindActionCreators(addContact, dispatch),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferPanelContainer)