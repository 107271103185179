import React, { Component } from 'react'
import Calculator from './Calculator'
import { connect } from 'react-redux'
import { setCurrencyData, setKrakenAsksData, setFeeListData } from '../store/loadedData/actions'
import { bindActionCreators } from 'redux'
import { getCurrencyData, getKrakenAsksData, getFeeListData } from '../services/RatesRequester'


class CalculatorContainer extends Component {

  componentDidMount() {
    getCurrencyData().then((ratesData) => {
      this.props.setCurrencyData(ratesData)
    })
    getKrakenAsksData().then((krakenData) => {
      this.props.setKrakenAsksData(krakenData)
    })
    getFeeListData().then((feeListData) => {
      this.props.setFeeListData(feeListData)
    })
  }

  render() {
    return (
      <Calculator
        currency={this.props.currency}
        krakenAsks={this.props.krakenAsks}
        feeList={this.props.feeList}
      />)
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.loadedData.currency,
    krakenAsks: state.loadedData.krakenAsks,
    feeList: state.loadedData.feeList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrencyData: bindActionCreators(setCurrencyData, dispatch),
    setKrakenAsksData: bindActionCreators(setKrakenAsksData, dispatch),
    setFeeListData: bindActionCreators(setFeeListData, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorContainer)