import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import MenuIcon from '@material-ui/icons/Menu'
import LanguageIcon from '@material-ui/icons/Language'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import Fab from '@material-ui/core/Fab'


export class Header extends Component {

  state = {
    leftMenu: false,
    languageMenu: false
  };

  toggleDrawer = (open) => () => {
    this.setState({
      leftMenu: open,
    })
  }

  toggleLanguage = (open) => () => {
    this.setState({
      languageMenu: open,
    })
  }
  render() {
    return (
      <div>
        <Fab id="menuButton" variant="extended" aria-label="Menu" onClick={this.toggleDrawer(true)}>
          <MenuIcon />
        </Fab>

        <Fab id="languageButton" variant="extended" aria-label="Menu"
          onClick={this.toggleLanguage(true)}
          buttonRef={node => {
            this.languageButton = node
          }}>
          <LanguageIcon />
        </Fab>

        <h1>Crypto Bay Exchange</h1>

        <Menu
          id="simple-menu"
          anchorEl={this.languageButton}
          open={Boolean(this.state.languageMenu)}
          onClose={this.toggleLanguage(false)}
        >
          <MenuItem onClick={this.toggleLanguage(false)}>Russian</MenuItem>
          <MenuItem onClick={this.toggleLanguage(false)}>English</MenuItem>
          <MenuItem onClick={this.toggleLanguage(false)}>Albanian</MenuItem>
        </Menu>

        <Drawer open={this.state.leftMenu} onClose={this.toggleDrawer(false)}>
          <div >
            <List>

              <ListItem>
                <Button label="Main"
                  component={Link}
                  to="/"
                  onClick={this.toggleDrawer(false)}>
                  Main page
                </Button>
              </ListItem>

              <ListItem>
                <Button label="About"
                  component={Link}
                  to="/about"
                  onClick={this.toggleDrawer(false)}>
                  About
                </Button>
              </ListItem>

              <ListItem>
                <Button label="TermsOfService"
                  component={Link}
                  to="/terms"
                  onClick={this.toggleDrawer(false)}>
                  Terms of service
                </Button>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    )
  }
}

export default Header