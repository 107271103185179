import React from 'react'
import { render } from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers'
import thunk from 'redux-thunk'
import { loadState, saveState } from './services/LocalStorage'
import { removeStaleData } from './services/Utils'

const persistedState = loadState()
removeStaleData(persistedState && persistedState.loadedData)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)))
store.subscribe(() => {
  const state = store.getState()
  saveState({
    fee: {
      feePerByteEstimated: state.fee.feePerByteEstimated,
      numberOfBlocksEstimated: state.fee.numberOfBlocksEstimated,
      calculationWay: state.fee.calculationWay,
    },
    converter: {
      value: state.converter.value,
      currency1: state.converter.currency1,
      currency2: state.converter.currency2,
      baseCurrency: state.converter.baseCurrency,
    },
    transfer: {
      btcAddress: state.transfer.btcAddress,
    },
    loadedData: {
      currency: state.loadedData.currency,
      krakenAsks: state.loadedData.krakenAsks,
      feeList: state.loadedData.feeList,
    },
    contacts: {
      contactsList: state.contacts.contactsList,
    }
  })
})
render(
  <Provider store={store}>
    <App />
  </Provider>
  , 
  document.getElementById('root'))
