import React, { Component } from 'react'
import marked from 'marked'
import { Config, getOption } from '../Config.js'

export class TermsOfService extends Component {
  state = {
    markdown: ''
  };
  componentDidMount() {
    const readmePath = require('./terms/terms-ru.md')
    fetch(readmePath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: marked(text)
            .replace(/\[SERVICE_NAME\]/g, getOption(Config.SERVICE_NAME))
            .replace(/\[SERVICE_SITE\]/g, getOption(Config.SERVICE_SITE))
        })
      })
  }

  render() {
    const { markdown } = this.state
    return (
      <section>
        <article dangerouslySetInnerHTML={{ __html: markdown }}></article>
      </section>
    )
  }
}

export default TermsOfService
