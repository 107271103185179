import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import FeePanelContainer from './FeePanelContainer.jsx'
import ConverterContainer from './ConverterContainer.jsx'
import TransferPanelContainer from './TransferPanelContainer.jsx'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import CurrencyContainer from './CurrencyContainer'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  textField: {
  },
  textFieldSelected: {
    background: theme.palette.secondary.main
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(3),
    border: 1,
    display: 'flex',
  },
  grid: {
    flexGrow: 1,
    display: 'flex',
    margin: 0,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  currencyText: {
    marginLeft: '10px',
    marginRight: '10px',
  },
})

export class Calculator extends Component {
  render() {
    const { classes } = this.props
    return (
      <div id="calculator">
        {(this.props.currency.timestamp) &&
          (this.props.krakenAsks.timestamp) &&
          (this.props.feeList.timestamp)
          ? <Fragment>
            <CurrencyContainer classes={classes} />
            <ConverterContainer classes={classes} />
            <Divider variant="middle" />
            <FeePanelContainer classes={classes} />
            <Divider variant="middle" />
            <TransferPanelContainer classes={classes} />
          </Fragment>
          :
          <Fragment>
            <LinearProgress />
          </Fragment>
        }
      </div>


    )
  }
}
export default withStyles(styles)(Calculator)