import React, { Component } from 'react'
import Currency from './Currency'
import { connect } from 'react-redux'
import {getEurUsdCurrency } from '../services/RatesRequester'


class CurrencyContainer extends Component {

  state = {
    eur: 0,
    usd: 0,
    usdTrend: 0,
    eurTrend: 0,
  }

  componentDidMount() {    
    this.setState({
      ...getEurUsdCurrency(this.props.currency.data),
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currency.timestamp !== this.props.currency.timestamp) {
      this.setState({
        ...getEurUsdCurrency(this.props.currency.data),
      })
    }
  }

  render() {
    return (
      <Currency
        eur={this.state.eur}
        usd={this.state.usd}
        eurTrend={this.state.eurTrend}
        usdTrend={this.state.usdTrend}
        classes={this.props.classes}
      />)
  }
}

const mapStateToProps = (state) => {
  return {
    currency: state.loadedData.currency,
  }
}

export default connect(mapStateToProps)(CurrencyContainer)